import React from "react";
import PropTypes from "prop-types";
import { TextField } from "@material-ui/core";
import NumberFormat from "react-number-format";
import { convertToInt } from "../../../../utils/convert-number";
import { useIsScenarioOwner } from "../../../../hooks/scenario.hooks";

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={({ floatValue }) => {
        onChange({
          target: {
            name: props.name,
            value: Math.abs(floatValue),
          },
        });
      }}
      thousandSeparator
      isNumericString
      allowLeadingZeros={false}
      prefix="$"
    />
  );
}

const ScenarioFinanceInput = ({
  title = "",
  description = "",
  handleChange = () => {},
  type = "",
  value,
  handleBlur = () => {},
  isOwner = true,
}) => {
  const handleChangeValue = (e) => {
    handleChange({ [type]: convertToInt(e.target.value) });
  };

  return (
    <div className="scenario-finance-input">
      <div className="scenario-finance-input__title">{title}</div>
      <div className="scenario-finance-input__sub-title">{description}</div>
      <TextField
        disabled={!isOwner}
        id="outlined-size-small"
        variant="outlined"
        size="small"
        className="scenario-finance-input__input"
        onChange={handleChangeValue}
        value={value || 0}
        onBlur={handleBlur}
        InputProps={{
          inputComponent: NumberFormatCustom,
        }}
      />
    </div>
  );
};

ScenarioFinanceInput.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
};

export default ScenarioFinanceInput;
