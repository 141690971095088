/* eslint-disable no-param-reassign */
import PropTypes from "prop-types";
import React from "react";
import { useIsScenarioOwner } from "../../../../hooks/scenario.hooks";
import { useMyInfo } from "../../../../hooks/user.hooks.ts";
import { getFullName } from "../../../../utils/user-data-util";
import UpdateScenarioFinance from "./update-scenario-finance";

function updateObjectById(arr, id, updatedObject) {
  const index = arr.findIndex((obj) => obj?.id === id);

  if (index !== -1) {
    arr[index] = { ...arr[index], ...updatedObject };
    return arr;
  }
  return arr;
}

const ScenarioFilterFinanceInput = ({
  title = "",
  setScenarioFilter = () => {},
  filterSelect = {},
}) => {
  const { data: userInfo = {} } = useMyInfo();
  const isOwner = useIsScenarioOwner();
  const { lvrSetting, applicant, borrowers = [] } = filterSelect;
  const handleChangeValue = (userId, value) => {
    const newBorrowers = updateObjectById(borrowers, userId, {
      ...value,
    });
    setScenarioFilter({
      lvrSetting: { ...lvrSetting, ...value },
      borrowers: newBorrowers,
    });
  };

  const renderInput = () => (
    <>
      {borrowers
        ?.filter((borrower) => isOwner || borrower.id === userInfo?.id)
        ?.map((borrower, i) => {
          if (i > applicant - 1) {
            return <></>;
          }

          if (!borrower?.id) {
            return <></>;
          }

          return (
            <div key={borrower.id}>
              <div className="text-center" style={{ fontWeight: "bold" }}>
                {getFullName(borrower?.member || borrower)}
              </div>
              <UpdateScenarioFinance
                userId={borrower.id}
                handleChangeValue={(val) => handleChangeValue(borrower.id, val)}
                financeValue={{
                  estimateExpenses: borrower?.estimateExpenses || 0,
                  estimateIncome: borrower?.estimateIncome || 0,
                }}
              />
            </div>
          );
        })}
    </>
  );

  return (
    <div className="scenario-filter-finance">
      <div className="scenario-filter-title">{title}</div>
      <div className="scenario-filter-item-container">{renderInput()}</div>
    </div>
  );
};

ScenarioFilterFinanceInput.propTypes = {
  title: PropTypes.string,
};

export default ScenarioFilterFinanceInput;
