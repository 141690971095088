import PropTypes from "prop-types";
import React from "react";
import ScenarioFinanceInput from "./scenario-finance-input";

const UpdateScenarioFinance = ({
  handleChangeValue = () => {},
  financeValue = {},
}) => {
  const { estimateExpenses = 0, estimateIncome = 0 } = financeValue;
  return (
    <>
      <ScenarioFinanceInput
        title="Current Income Estimate PM"
        description="(Based on linked bank accounts)"
        type="estimateIncome"
        handleChange={handleChangeValue}
        value={estimateIncome}
      />
      <ScenarioFinanceInput
        title="Current Expenses Estimate PM"
        description="(Based on linked bank accounts)"
        type="estimateExpenses"
        handleChange={handleChangeValue}
        value={estimateExpenses}
      />
    </>
  );
};

UpdateScenarioFinance.propTypes = {
  handleChangeValue: PropTypes.func,
  financeValue: PropTypes.object,
};

export default UpdateScenarioFinance;
