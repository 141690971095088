import {
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import classNames from "classnames";
import React, { useContext } from "react";
import ScenarioItemContext from "../../../../context/scenario-item-context";
import {
  borderColor,
  primaryColor,
  textColor,
} from "../../../../scss/colors.scss";
import { fontSemiBold } from "../../../../scss/fonts.scss";
import { convertToInt } from "../../../../utils/convert-number";
import { isNotEmptyObject } from "../../../../utils/object-util";
import ScenarioFilterButton from "./scenario-filter-button";
import { useIsScenarioOwner } from "../../../../hooks/scenario.hooks";

const propertyTypeMoreList = [
  "Bedsitter / Bachelor Studio Apartments",
  "Company Titles",
  "Crown Leasehold",
  "Inner City / High Density Apartments",
  "Luxury Housing",
  "Multiple Units - Single Title",
  "National Rental Affordability Scheme (NRAS)",
  "Rural",
  "Rural - Residential",
  "Second Mortgage",
  "Serviced Apartments",
  "Small Residential Apartments 40 to 50 sq. meter",
  "Small Residential Apartments < 40 sq. meter",
  "Student Accommodation",
  "Vacant Land - Regional",
  "Warehouse Accommodation",
];

const useStyles = makeStyles((theme) => ({
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  menuItem: {
    justifyContent: "flex-start",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    fontSize: 14,
    fontFamily: "ProximaNovaRegular",
  },
  textField: {
    margin: 0,
    "& .MuiInputBase-input": {
      fontSize: 30,
      fontFamily: "ProximaNovaRegular",
      width: 50,
      height: 60,
      paddingTop: 0,
      paddingBottom: 0,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: `${borderColor}`,
      },
      "&.Mui-focused fieldset,&:hover fieldset": {
        border: `1px solid ${primaryColor}`,
      },
    },
  },
  selectComponent: {},
  inputLabel: {
    fontSize: 14,
    fontFamily: `${fontSemiBold}`,
    color: `${textColor}`,

    "&.Mui-focused": {
      color: `${primaryColor}`,
    },
    "&.MuiInputLabel-shrink": {
      fontSize: 16,
    },
  },
}));

const ScenarioFilterItem = ({
  title = "",
  itemList = [],
  filterSelect = {},
  filterType = "",
  setScenarioFilter,
}) => {
  const classes = useStyles();
  const isOwner = useIsScenarioOwner();
  const { scenarioFilterData = {} } = useContext(ScenarioItemContext);
  const selectedFilter = filterSelect[filterType];
  const handleChangePeopleDependant = (event) => {
    if (filterType === "dependants") {
      setScenarioFilter({
        ...filterSelect,
        dependants: convertToInt(event.target.value) || 0,
      });
    } else
      setScenarioFilter({
        ...filterSelect,
        [filterType]: event.target.value,
      });
  };
  const handleSelectFilterItem = (value) => {
    const scenarioFilter = () => {
      switch (filterType) {
        case "features":
          if (selectedFilter.includes(value)) {
            return {
              ...filterSelect,
              [filterType]: selectedFilter.filter((item) => item !== value),
            };
          }
          return {
            ...filterSelect,
            [filterType]: selectedFilter.concat(value),
          };
        case "propertyType":
          return { ...filterSelect, [filterType]: value };
        default:
          return { ...filterSelect, [filterType]: value };
      }
    };
    setScenarioFilter(scenarioFilter());
  };
  const checkSelectedFilter = (propertyText) => {
    if (Array.isArray(selectedFilter) && selectedFilter.length > 0) {
      return selectedFilter.includes(propertyText);
    }
    return propertyText === selectedFilter;
  };

  return (
    <div>
      <div className="scenario-filter-title">{title}</div>
      <div className="scenario-filter-item-container">
        {itemList.map((scenarioType) => {
          return (
            <ScenarioFilterButton
              isDisabled={
                (filterType === "scenarioType" &&
                  isNotEmptyObject(scenarioFilterData)) ||
                (filterSelect.scenarioId && !isOwner)
              }
              key={`scenarioTypekey+${scenarioType?.key}`}
              scenarioType={scenarioType}
              buttonText={scenarioType?.text}
              onClick={() => handleSelectFilterItem(scenarioType?.key)}
              isSelected={checkSelectedFilter(scenarioType?.key)}
            />
          );
        })}
        {filterType === "dependants" && (
          <div>
            <div className="dependants-number">Add Number</div>
            <TextField
              disabled={filterSelect.scenarioId && !isOwner}
              pattern="[0-9]*"
              classes={{ root: classes.textField }}
              className={classNames({ selected: filterSelect.dependants > 3 })}
              id="outlined-multiline-flexible"
              name="monthlyRent"
              margin="normal"
              type="tel"
              variant="outlined"
              value={filterSelect.dependants >= 0 ? filterSelect.dependants : 0}
              onChange={handleChangePeopleDependant}
              inputProps={{
                inputMode: "numeric",
                min: 0,
                max: 10,
                step: 1,
                maxLength: 3,
              }}
            />
          </div>
        )}
        {filterType === "propertyType" && (
          <FormControl
            variant="outlined"
            classes={{ root: classes.formControl }}
            className={classNames("scenario-filter-form-control", {
              selected: propertyTypeMoreList.includes(
                filterSelect.propertyType
              ),
            })}
          >
            <InputLabel
              className={classes.inputLabel}
              id="demo-simple-select-outlined-label"
            >
              Select Other
            </InputLabel>
            <Select
              disabled={filterSelect.scenarioId && !isOwner}
              classes={{ root: classes.selectComponent }}
              variant="outlined"
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={
                propertyTypeMoreList.includes(filterSelect.propertyType)
                  ? filterSelect.propertyType
                  : ""
              }
              onChange={handleChangePeopleDependant}
              label="Select Other"
            >
              {propertyTypeMoreList.map((item) => (
                <MenuItem
                  className={classes.menuItem}
                  key="propertyType"
                  value={item}
                >
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </div>
    </div>
  );
};

export default ScenarioFilterItem;
